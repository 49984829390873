import BasketService from "@/services/dashboard/BasketService";
import OrderService from "@/services/orders/OrderService";

const state = {
	client: null,
	products: [],
	addresses: [],
	contactDetails: [],
	terms: [],
	config: {},

	activeDl: 1,
	retailPrice: 0,
	clientPrice: 0,
	data: {
		address: null,
		contactDetail: null,
		additionalInformation: null,
	},

	dls: [],

	confirmation: null,

	validForm: false,
};

const getters = {
	DATA: state => state.data,

	CLIENT: state => state.client,
	PRODUCTS: state => state.products,
	ADDRESSES: state => state.addresses,
	CONTACT_DETAIL: state => state.contactDetails,
	ADDITIONAL_INFORMATION: state => state.data.additionalInformation,
	TERMS: state => state.terms,
	CONFIG: state => state.config,

	DLS: state => state.dls,

	ACTIVE_DL: (state, getters, rootState, rootGetters) => {
		if(rootGetters['AUTH_STORE/DL_FLEX'] !== null){
			return rootGetters['AUTH_STORE/DL_FLEX'];
		}
		for (let i = 0; state.dls.length > i; i++) {
			if (state.dls[i] > state.retailPrice) {
				return i;
			}
		}
		return state.dls.length;
	},
	RETAIL_PRICE: state => state.retailPrice,
	CLIENT_PRICE: state => state.clientPrice,

	CONFIRMATION: state => state.confirmation,

	VALID_FORM: state => state.validForm,
};

const mutations = {
	DATA_SET(state, {name, value}) {
		state.data[name] = value;
	},

	CLIENT_SET(state, value) {
		state.client = value;
	},
	PRODUCTS_SET(state, value) {
		state.products = value;
	},
	ADDRESSES_SET(state, value) {
		state.addresses = value;
	},
	CONTACT_DETAILS_SET(state, value) {
		state.contactDetails = value;
	},
	ADDITIONAL_INFORMATION_SET(state, value) {
		state.data.additionalInformation = value;
	},
	TERMS_SET(state, value) {
		state.terms = value;
	},
	CONFIG_SET(state, value) {
		state.config = value;
	},

	DLS_SET(state, value) {
		state.dls = value;
	},

	RETAIL_PRICE_SET(state, value) {
		state.retailPrice = value;
	},
	CLIENT_PRICE_SET(state, value) {
		state.clientPrice = value;
	},


	CONFIRMATION_SET(state, value) {
		state.confirmation = value;
	},

	VALID_FORM_SET(state, value) {
		state.validForm = value;
	},

};

const actions = {

	getBasket({commit, getters}) {
		return new Promise((resolve, reject) => {
			BasketService.index()
				.then((resp) => {
					if (resp.data.success) {
						commit('PRODUCTS_SET', resp.data.products);
						commit('ADDRESSES_SET', resp.data.addresses);
						commit('CLIENT_SET', resp.data.client);
						commit('CONTACT_DETAILS_SET', resp.data.contactDetails);
						commit('DLS_SET', resp.data.dls);
						if (resp.data.addresses.length) {
							commit('DATA_SET', {
								name: 'address',
								value: resp.data.addresses.find(i => i.primary) ? resp.data.addresses.find(i => i.primary).id : resp.data.addresses[0].id,
							});
						}
						if (resp.data.contactDetails.length) {
							commit('DATA_SET', {
								name: 'contactDetail',
								value: resp.data.contactDetails.find(i => i.primary) ? resp.data.contactDetails.find(i => i.primary).id : resp.data.contactDetails[0].id,
							});
						}

						commit('TERMS_SET', resp.data.terms);
						commit('CONFIG_SET', resp.data.config);

						let products = resp.data.products.filter(i => i.quantity > 0 && ['active', 'coming_soon'].includes(i.status.slug));
						let retailPrice = 0;
						let clientPrice = 0;

						for (let i = 0; i < products.length; i++) {
							if (products[i].retailPrice.promotional_price) {
								retailPrice += products[i].retailPrice.promotional_price * products[i].quantity;
							} else {
								retailPrice += products[i].retailPrice.price * products[i].quantity;
							}
						}

						commit('RETAIL_PRICE_SET', retailPrice)

						for (let i = 0; i < products.length; i++) {
							if (products[i].prices[getters.ACTIVE_DL].promotional_price) {
								clientPrice += products[i].prices[getters.ACTIVE_DL].promotional_price * products[i].quantity;
							} else {
								clientPrice += products[i].prices[getters.ACTIVE_DL].price * products[i].quantity;
							}
						}

						commit('CLIENT_PRICE_SET', clientPrice)
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	updateBasket({getters, commit}) {
		commit('LOADING_UPDATE_BASKET_SET', false)
		return new Promise((resolve, reject) => {
			BasketService.update(getters.PRODUCTS_WITH_QUANTITY.map(i => {
				return {
					id: i.id,
					quantity: i.quantity,
				}
			}))
				.then((resp) => {
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	},


	orderNow({getters, commit}) {
		return new Promise((resolve, reject) => {
			OrderService.store(getters.DATA)
				.then((resp) => {
					if (resp.data.success) {
						commit('CONFIRMATION_SET', resp.data.data);
					}
					resolve(resp);
				})
				.catch(err => {
					reject(err);
				});
		});
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
